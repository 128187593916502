<template>
    <div class="password">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="原密码">
                <el-input
                    v-model="form.password"
                    type="password"
                    placeholder="请输入原密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="新密码">
                <el-input
                    v-model="form.newspass"
                    type="password"
                    placeholder="请输入新密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
                <el-input
                    v-model="form.repass"
                    type="password"
                    placeholder="请再次输入新密码"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { resetPass } from "@/api/user.js";
import { encryptionPassWord } from "../../utils/encryptionpassword";
import md5 from "js-md5";
export default {
    name: "VideoNewPassword",

    data() {
        return {
            form: {
                password: "",
                newspass: "",
                repass: "",
            },
        };
    },

    mounted() {},

    methods: {
        async onSubmit() {
            if (!this.form.password) {
                this.$message.error("原密码不能为空！");
                return;
            }
            if (!this.form.newspass) {
                this.$message.error("新密码不能为空！");
                return;
            }
            if (this.form.newspass != this.form.repass) {
                this.$message.error("新密码不一致！");
                return;
            }
            let oldPassword = this.form.password;
            let password = this.form.newspass;
            let reset_data = {
                oldPassword: encryptionPassWord(oldPassword), //原密码
                password: encryptionPassWord(password), //新密码
            };
            let res = await resetPass(reset_data);
            if (res.code == 200) {
                this.$message.success("密码修改成功");
                this.$store.state.token = "";
                this.$router.push("/");
            } else {
                this.$message.error("密码修改失败！");
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item {
    margin: 0;
    padding: 20px;
    background-color: #fff;
    input {
        border: none;
    }
}
/deep/ .el-form-item + .el-form-item {
    margin-top: 2px;
}
</style>